import * as React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import NewsletterBanner from "../components/newsletterBanner";

import backgroundStillJpg from "../images/hero_banner.jpg";

const query = graphql`
  query {
    allStrapiPage(limit: 4, sort: { fields: publication_date, order: DESC }) {
      nodes {
        id
        Titolo
        Permalink
        Copertina {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Chi siamo - Sterea Medicina e Viaggi" />

      <div className="sterea-center-column-wrapper overflow-hidden relative">
        <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-40 z-20"></div>
        <video
          playsInline
          autoPlay
          muted
          loop
          id="bgvid"
          poster={backgroundStillJpg}
          className="absolute home-page-video-height w-screen object-cover object-center"
        >
          <source
            media="(min-width:1280px)"
            src="https://res.cloudinary.com/mohststudio/video/upload/v1619707443/Sterea_Medical_travel_senza_watermark_FINAL_DESKTOP_1_do0jy5.mp4"
            type="video/mp4"
          />
          <source
            src="https://res.cloudinary.com/mohststudio/video/upload/v1619707443/Sterea_Medical_travel_senza_watermark_FINAL_DESKTOP_1_do0jy5.mp4"
            type="video/mp4"
          />
        </video>
        <div className="sterea-center-column home-page-video-height z-20 flex justify-center lg:justify-end items-center">
          <h1 className="w-2/3 text-white text-center lg:text-left lg:leading-tight text-xl lg:text-5xl font-bold">
            Grazie, ti manderemo soltanto le migliori offerte esclusive!
          </h1>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
